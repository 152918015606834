@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes hide {
  100% {
    opacity: 1;
  }
  0% {
    opacity: 0;
  }
}
.notify-window-modal-wrapper {
  background: rgba(0, 0, 0, 0.7);
  display: block;
  font-size: 14px;
  text-align: left;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  animation: show 0.4s forwards;
}
.notify-window-modal-wrapper * {
  box-sizing: border-box;
}

.notify-window-modal-window {
  background: #fff;
  width: 100%;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.notify-window-modal-window.window-big {
  max-width: 800px;
}
.notify-window-modal-window.window-medium {
  max-width: 600px;
}
.notify-window-modal-window.window-small {
  max-width: 320px;
}

.notify-window-close {
  padding: 8px 8px 0;
  text-align: right;
  width: 100%;
}

.close-icon {
  cursor: pointer;
  display: inline-block;
}
.close-icon img {
  display: block;
  width: 32px;
  height: auto;
}

.notify-window-modal-container {
  padding: 8px 16px 16px;
  width: 100%;
  height: auto;
  max-height: 90vh;
  overflow-y: auto;
}